/* eslint-disable @typescript-eslint/ban-ts-comment */
import { pick } from 'lodash';
import { ComponentType, ElementRef, forwardRef } from 'react';

import type {
  ColorCompound,
  ColorFromPalette,
} from '@ansarada/colors/lib/design-system-colors/utils';
import * as uiColorsV1 from '@ansarada/colors/lib/design-system-colors/v1';

const gasColors = pick(uiColorsV1, [
  'grey',
  'mercury',
  'chlorine',
  'krypton',
  'citrus',
  'helium',
  'bromine',
  'neon',
  'radon',
  'argon',
]);

const RED = 0.2126;
const GREEN = 0.7152;
const BLUE = 0.0722;

const GAMMA = 2.4;

const CONTRAST_RATIO_THRESHOLD = 4.5;

type RGB = Record<'r' | 'g' | 'b', number>;

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  // if (!result) throw new Error(`Bad hex color: ${hex}`);

  return {
    // @ts-ignore: TODO FIX THIS
    r: parseInt(result?.[1], 16),
    // @ts-ignore: TODO FIX THIS
    g: parseInt(result?.[2], 16),
    // @ts-ignore: TODO FIX THIS
    b: parseInt(result?.[3], 16),
  };
}

function getLuminance(r: number, g: number, b: number) {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, GAMMA);
  });

  return a[0] * RED + a[1] * GREEN + a[2] * BLUE;
}

/** @ref https://stackoverflow.com/questions/9733288/how-to-programmatically-calculate-the-contrast-ratio-between-two-colors */
function getContrastRatio(rgb1: RGB, rgb2: RGB) {
  const luminance1 = getLuminance(rgb1.r, rgb1.g, rgb1.b);
  const luminance2 = getLuminance(rgb2.r, rgb2.g, rgb2.b);

  const brightest = Math.max(luminance1, luminance2);
  const darkest = Math.min(luminance1, luminance2);

  return (brightest + 0.05) / (darkest + 0.05);
}

export function getContrastTextColor(
  backgroundColor: string,
  textColors: string[] = [uiColorsV1.order, uiColorsV1.chaos],
  threshold = CONTRAST_RATIO_THRESHOLD,
) {
  const bgColorRgb = hexToRgb(backgroundColor);
  const contrastRatios = textColors.map((textColor) =>
    getContrastRatio(bgColorRgb, hexToRgb(textColor)),
  );

  if (threshold) {
    const index = contrastRatios.findIndex((r) => r >= threshold);
    if (index === -1) return uiColorsV1.neon._400;
    return textColors[index];
  }

  const highestContrast = Math.max(...contrastRatios);
  const highestContrastIndex = contrastRatios.findIndex((r) => r === highestContrast);

  return textColors[highestContrastIndex];
}

type Gas = keyof typeof gasColors;
type ColorVariant = keyof ColorCompound;

type ColorPalette = `${Gas}.${ColorVariant}`;

export type AnsaradaColor = ColorPalette | ColorFromPalette;

export function parseColor(color: AnsaradaColor): string;
export function parseColor(color: AnsaradaColor | undefined): string | undefined;
export function parseColor(color: AnsaradaColor | undefined): string | undefined {
  if (!color) return undefined;

  const [gas, variant] = color.split('.') as [Gas, ColorVariant];
  if (!gas || !variant) return color as string;

  return uiColorsV1[gas][variant];
}

function transitionColorToVariant(color: string, variant: ColorVariant): ColorFromPalette | null {
  for (const [_, variantsCompound] of Object.entries(gasColors)) {
    if (Object.values(variantsCompound).includes(color as ColorFromPalette))
      return variantsCompound[variant];
  }

  return null;
}

export function withAnsaradaColor<P extends object>(
  Component: ComponentType<P>,
  options: {
    colorFieldKeys: (keyof P)[];
    forceColorVariant?: ColorVariant;
  },
) {
  const { colorFieldKeys, forceColorVariant } = options;

  const component = forwardRef<ElementRef<typeof Component>, P>((props, ref) => {
    const colorFields = Object.entries(pick(props, colorFieldKeys));
    let parsedColorFields = colorFields.map(
      ([key, color]) => [key, parseColor(color as AnsaradaColor)] as const,
    );

    const enableForcingColor = !(props as { disableColorForcing?: boolean }).disableColorForcing;

    if (forceColorVariant && enableForcingColor) {
      parsedColorFields = parsedColorFields.map(
        ([key, color]) =>
          [key, transitionColorToVariant(color, forceColorVariant) ?? uiColorsV1.chaos] as const,
      );
    }

    return <Component {...props} {...Object.fromEntries(parsedColorFields)} ref={ref} />;
  });

  component.displayName = Component.displayName;
  return component;
}

/**
 * Converts color (hex) to corresponding color level by mapping and flattening the 'gasColors' object
 *
 * @param {string} hex - the hex for the input color
 * @returns {ColorCompound | null}
 */
export const hexToColorLevels = (hex: string): ColorCompound | null => {
  const entries = Object.entries(gasColors);
  const flatEntries = entries.flatMap(([colorKey, colorLevels]) => {
    return Object.entries(colorLevels).map(([levelKey, color]) => ({ colorKey, levelKey, color }));
  });
  const colorLevels = flatEntries.find((flattenedEntry) => flattenedEntry.color === hex);

  return colorLevels ? gasColors[colorLevels.colorKey] : null;
};
